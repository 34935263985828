import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconArrowLeft({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M20 12H4" />
                <path d="M10 18L4 12L10 6" />
            </svg>
        </>
    );
}

IconArrowLeft.propTypes = propTypes;
IconArrowLeft.defaultProps = defaultProps;

export default IconArrowLeft;
