import React from 'react';
import PropTypes from 'prop-types';

import Link from 'atoms/link/Link';

import './BlogItem.scss';

const defaultProps = {
    className: '',
    datePublished: '',
    datePublishedFormatted: '',
    description: '',
    heading: '',
    url: {},
};

const propTypes = {
    className: PropTypes.string,
    datePublished: PropTypes.string,
    datePublishedFormatted: PropTypes.string,
    description: PropTypes.string,
    heading: PropTypes.string,
    url: PropTypes.object,
};

function BlogItem({
    className,
    datePublished,
    datePublishedFormatted,
    description,
    heading,
    url,
}) {
    return (
        <>
            <article className={`m-blog-item ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="m-blog-item__date-container">
                        {datePublishedFormatted && (
                            <time className="m-blog-item__date" dateTime={datePublished}>{datePublishedFormatted}</time>
                        )}
                    </div>
                    <div className="m-blog-item__content-container">
                        <h2 className="m-blog-item__heading">
                            <Link className="m-blog-item__heading-link" to={url}>{heading}</Link>
                        </h2>
                        {description && (
                            <p className="m-blog-item__description">{description}</p>
                        )}
                    </div>
                </div>
            </article>
        </>
    );
}

BlogItem.propTypes = propTypes;
BlogItem.defaultProps = defaultProps;

export default BlogItem;