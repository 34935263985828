import React from 'react';
import PropTypes from 'prop-types';

import BlogItem from 'molecules/blogitem/BlogItem';

import './BlogListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function BlogListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-blog-listing ${className}`}>
                {listing.map(({ data, uid }, index) => {
                    const datePublished = data?.ma_date_published;
                    const datePublishedFormatted = data?.ma_date_published_formatted;
                    const description = data?.page_meta_description?.raw?.[0]?.text;
                    const heading = data?.ma_heading?.raw?.[0]?.text;
                    const url = {
                        link_type: 'Document',
                        type: 'blog_article',
                        uid: uid
                    }

                    return (
                        <BlogItem
                            className="o-blog-listing__item"
                            datePublished={datePublished}
                            datePublishedFormatted={datePublishedFormatted}
                            description={description}
                            heading={heading}
                            key={`{${index}}-blog-item`}
                            url={url}
                        />
                    );
                })}
            </div>
        </>
    );
}

BlogListing.propTypes = propTypes;
BlogListing.defaultProps = defaultProps;

export default BlogListing;