import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import Shell from 'layout/shell/Shell';
import Pagination from 'molecules/pagination/Pagination';
import BlogListing from 'organisms/bloglisting/BlogListing';
import Masthead from 'organisms/masthead/Masthead';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    pageContext: PropTypes.shape({
        nextPagePath: PropTypes.string,
        numberOfPages: PropTypes.number,
        pageNumber: PropTypes.number,
        previousPagePath: PropTypes.string,
    }).isRequired,
};

function BlogListingPage({
    data,
    location: {
        pathname,
    },
    pageContext,
}) {
    const blogListing = data?.allPrismicBlogArticle?.nodes;
    const doc = data?.allPrismicBlogListing?.nodes?.[0]?.data;
    const pageDescription = doc?.page_meta_description?.raw?.[0]?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.raw?.[0]?.text;
    const mastheadDescription = doc?.ma_description?.raw?.[0]?.text;
    const mastheadHeading = doc?.ma_heading?.raw?.[0]?.text;

    return (
        <Shell
            className="t-blog-listing"
            pathname={pathname}
            seoDescription={pageDescription}
            seoImage={pageSocialImage}
            seoTitle={pageTitle}
        >
            <main className="l-grid__main t-blog-listing__main" id="main-content">
                <Masthead
                    description={mastheadDescription}
                    heading={mastheadHeading}
                />
                <BlogListing
                    listing={blogListing}
                />
                {(pageContext.nextPagePath || pageContext.previousPagePath) && (
                    <Pagination
                        nextPageUrl={pageContext.nextPagePath}
                        prevPageUrl={pageContext.previousPagePath}
                    />
                )}
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($skip: Int, $limit: Int) {
        allPrismicBlogArticle(
            limit: $limit,
            skip: $skip,
            sort: {
                fields: [data___ma_date_published],
                order: DESC
            }
        ) {
            nodes {
                data {
                    ma_date_published
                    ma_date_published_formatted: ma_date_published(formatString: "D MMMM YYYY")
                    ma_heading {
                        raw
                    }
                    page_meta_description {
                        raw
                    }
                }
                uid
            }
        }
        allPrismicBlogListing {
            nodes {
                data {
                    ma_heading {
                        raw
                    }
                    page_meta_description {
                        raw
                    }
                    page_meta_title {
                        raw
                    }
                }
            }
        }
    }
`;

BlogListingPage.propTypes = propTypes;
BlogListingPage.defaultProps = defaultProps;

export default withPreview(BlogListingPage);